<template>
  <v-row class="text-center">
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
    <v-spacer></v-spacer>
    <v-col align="center" class="col-12 col-sm-3">
      <v-card class="pa-5">
        <v-form
          v-if="!isUsernameChanged"
          @submit.prevent
          ref="form"
          v-model="valid"
        >
          <h2>Change Your Log-In</h2>
          <div class="error--text mt-3">Please enter an email address that you will use to log in from now on. That email address will be also be used if you forget your password.</div>
          <v-text-field
            v-model="email1"
            :rules="emailRules"
            ref="emailField"
            label="New Email"
            required
            @keydown.enter="submit"
          ></v-text-field>
          <v-text-field
            v-model="email2"
            :rules="emailRules"
            label="Confirm Email"
            required
            @keydown.enter="submit"
          ></v-text-field>
          <v-btn
            :loading="loading"
            @click="submit"
            color="primaryButton"
            class="mt-3 white--text"
          >
            Submit
          </v-btn>
          <div class="error--text mt-3">{{ error }}</div>
        </v-form>
        <div v-else>Username change successful.</div>
      </v-card>
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ChangeUsername',

    data () {
      return {
        emailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid.'
        ],
        error: '',
        isUsernameChanged: false,
        loading: false,
        email1: '',
        email2: '',
        valid: false
      }
    },

    mounted () {
      this.$refs.emailField.focus()
    },

    methods: {
      submit () {
        this.error = ''
        this.isUsernameChanged = false
        if (this.valid && !this.loading) {
          this.loading = true
          this.$store.dispatch('updateUsername', { newUsername: this.email1 })
          .then(() => {
            this.loading = false
            this.isUsernameChanged = true
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      }
    }
  }
</script>
